// src/Components/Catalog.jsx
import React from "react";
import data from "./DataInfo"; // Ajusta la ruta según tu estructura de carpetas
import Card from "./Card";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Catálogo() {
  const data = useSelector((state) => state.shirts.shirts);
  return (
    <div className="bg-white flex justify-center mx-auto h-full w-full">
      <div className="p-6 gap-20 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">
        {data?.map((product) => (
          <Link to={`/detail/${product.id}`} key={product.id}>
            <Card
              key={product.id}
              img1={product?.images[0]}
              img2={product?.images[1]}
              title={product.title}
              price={product.price}
            />
          </Link>
        ))}
      </div>
    </div>
  );
}
