import "./App.css";
import React from "react";

import Rutas from "./Routes/Rutas";

function App() {
  return (
    <div className="">
      <Rutas />
    </div>
  );
}

export default App;
